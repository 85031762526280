<template>
  <v-select
      :items="GetAll"
      @change="ChangeDropDown"
      v-model="selected"
      auto-select-first
      hide-details
      filled
      :dense="dense"
  >
    <template v-slot:selection="{ item, index }">
      <v-img max-width="15px" class="rounded ma-2" :src="APP_URL + item.value.img"></v-img>  {{ ' +'+item.value.code }}
    </template>
    <template v-slot:item="{ item }">
      <v-img max-width="20px" class="rounded ma-2" :src="APP_URL + item.value.img"></v-img>  {{ ' +'+item.value.code }}
    </template>

  </v-select>
</template>

<script>
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "PhoneCodesDropDown" ,
  props : {
    item : null,
    dense:false
  },
  data(){
    return {
      selected : { id :0 , code:'' , img:''},
      loading:false,
      APP_URL
    }
  },
  computed:{
    GetAll(){
      return this.$store.getters['regionsModule/GetAllActivatedCountries']?.map((s , index)=>{
        return {
          text:s.name,
          value: {
            id : s.id,
            code:s.phoneCode,
            img: s.imagePath
          }
        }
      });
    }
  },
  mounted() {
    this.initialize();
  },
  watch:{
    item(val){
      this.initialize();
    }
  },
  methods:{
    GetByCode(){
      this.selected= this.GetAll.filter(s=>{
        return s.value.code === this.item.code
      })[0]?.value??this.GetAll[0]?.value??this.selected;
    },
    initialize(){
      debugger;
      if(this.item && this.item.code !==''){
        this.GetByCode();
      }else{
        this.selected = this.GetAll[0]?this.GetAll[0].value : this.selected;
        this.ChangeDropDown(this.selected);
      }
    },
    ChangeDropDown(item){
      this.$emit('Selected' , item);
    }

  }
}
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: yellow !important;
}
</style>