import {GOOGLE_MAP_API_KEY, GOOGLE_MAP_API_Link, FCM_SERVER_KEY, IP_GEOLOCATION_API_KEY} from "@/Helpers/Config";
import axios from "axios";

class AppHelper {
    GetLang(){
        return localStorage.getItem('lang')??"ar";
    }

     GetCountryFromIp(){
        return Promise.resolve('EG')
    }
    ChangeLanguage(){
        let lang = localStorage.getItem('lang');
        if(lang === 'ar'){
            lang = 'en';
        }else{
            lang = 'ar';
        }
        localStorage.setItem('lang' , lang);
        window.location.reload();
    }
    async subscribeTokenToTopic(token, topic) {
        await fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'key='+FCM_SERVER_KEY
            })
        }).then(response => {
            console.log(response)
            if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
            }
            console.log('Subscribed to "'+topic+'"');
        }).catch(error => {
            console.error(error);
        });
    }
    GetCurrentLocationFromCoords(coords , lang){
        let link  = GOOGLE_MAP_API_Link+coords.lat +','+coords.lng;
        link+='&language=';
        link+=lang
        link+= '&key=';
        link+=GOOGLE_MAP_API_KEY;
        return Promise.resolve(axios.get(link));
    }
    GetServicePlaceId(){
        let user = JSON.parse(localStorage.getItem('user'));
        if(user){
         return user.servicePlaceId?user.servicePlaceId : null
        }
        return null;
    }

    GetBranchId() {
        let user = JSON.parse(localStorage.getItem('user'));
        if(user){
            return user.branchId?user.branchId : null;
        }
        return null;
    }

    GetIdForChat(forMessage = false){
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {
            UserId : null,
            PlaceId: null,
            BranchId: null
        };
        if(user.rankBaseName === 'BranchManger')obj.BranchId = user.branchId ;
        else if(user.rankBaseName === 'ServiceSupplier'){
            obj.PlaceId= user.servicePlaceId
            if(forMessage){
                obj.UserId = user.id;
            }
        }
        else obj.UserId= user.id;
        return obj;
    }
}

export  default  new AppHelper();