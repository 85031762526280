<template>

<div class="preloader" >
  <v-container class="text-center con_pos align-content-center" >

      <img
          ref="img-loader"
          :src="require('../../assets/Logo2/logo.png')"
          alt="logo"
          class="me-3  img-loader v-responsive__sizer"
      >

  </v-container>

</div>
  
</template>

<script>
import AppCircularLoader from './AppCircularLoader.vue'
export default {
  components: { AppCircularLoader },
    name: "AppPreloader",
    data(){
      return{
        load_class : false
      }
    },

}
</script>
<style scoped>
    .preloader{
        height: 100vh;
        text-align: center;
      background-color: #e5e5e5;

    }

    .con_pos{
        position: relative;
        top: 25%;
    }
    .img-loader{
      max-height:400px;
      max-width:350px;
      animation: change_border 1s infinite alternate;
    }
    @keyframes change_border {

      from {opacity: .4;}

      to {opacity:1;}
    }
    @media  screen and (max-width: 900px) {
      .img-loader{
        max-height:250px;
        max-width:200px;
        animation: change_border 1s infinite alternate;
      }
    }
</style>