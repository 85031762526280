<template>

    <v-system-bar fixed v-if="hideSysBar" ref="system_bar"  class=" app-system-bar">
      <v-container class="container--fluid">
        <v-row class="top-header-row">

          <v-col cols="12" xl="12" class="align-center pa-0 ma-0" lg="12"  md="12" sm="12">
              <ul class="header-ul pa-0 mt-3" style="width: 100%!important;" >
                <li class="" v-if="!GetLoginStatus">
                  <v-btn
                      link
                      text
                      @click="CallClientAreaDialog('login')"
                      plain
                      small
                      class="header-ul-btn"
                      color="white">
                      <v-icon  class="ma-2 icon" color="white" > mdi-login-variant  </v-icon>

                      {{$t('Login')}}
                  </v-btn>

                </li>
                <li class="" v-if="!GetLoginStatus">
                  <v-btn
                      @click="CallClientAreaDialog('register')"
                      link
                      ref="register_btn"
                      text
                      small
                      plain
                      class="header-ul-btn pl-0 pr-0"
                      color="white">
                    <v-icon  class="ma-1 icon" color="white" > mdi-account-plus </v-icon>

                    {{$t('Register')}}
                  </v-btn>

                </li>
                <li class="" v-if="GetLoginStatus">
                  <v-btn
                      @click="Logout"
                      link
                      text
                      small
                      class="header-ul-btn pl-0 pr-0"
                      color="white">
                    <v-icon  class="ma-2 icon" color="white" > mdi-logout  </v-icon>

                    {{$t('Logout')}}
                  </v-btn>

                </li>
                <li class="custom-float-right lang">
                  <v-btn
                      @click="LanguageChange"
                      link
                      plain
                      text
                      small
                      class="header-ul-btn "
                      color="white">

                    {{$t('lang')}}
                  </v-btn>

                </li>
              </ul>
          </v-col>

          <v-col  v-if="false" cols="12" md="4" lg="4" sm="4"  class="custom-text-left">
            <ul class="header-ul pa-0 mt-3">

              <li class="">
                <v-btn
                    @click="LanguageChange"
                    link
                    plain
                    text
                    large
                    class="header-ul-btn lang"
                    color="white">

                  {{$t('lang')}}
                </v-btn>

              </li>
            </ul>
          </v-col>

        </v-row>
      </v-container>
    </v-system-bar>



</template>

<script>
import AppHelper from "@/Helpers/AppHelper";

export default {
  name: "AppTopHeader",
  props:{
    hideSysBar : false
  }
  ,data(){
    return {

    }

  }
  ,computed:{
    GetLoginStatus(){
      return this.$store.getters['authModule/IsLoggedIn'];
    },
  }
  ,methods:{
    LanguageChange(){
      AppHelper.ChangeLanguage();
    },
    Logout(){
      this.$store.dispatch('authModule/logout').then(res=>{
            window.location.reload();
      });
    },
    CallClientAreaDialog(type){
      this.$emit('OnClientArea' , type);
    },
  }
}
</script>

<style scoped>
.header-ul-btn{
  font-size: .9rem !important;
  font-weight: 600!important;
  letter-spacing: 0!important;
}

.lang{

  border-radius: 0;
  padding: 0;
  height: 100%;
}
.header-ul-btn .icon{
  font-size: 1.2rem;
}
.top-header-row{
  max-height: 130px !important;
}
@media screen and (max-width: 900px){
  .header-ul-btn{
    font-size: .8rem !important;
    font-weight: 600!important;
    letter-spacing: 0!important;
  }
  .header-ul-btn .icon{
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 400px) {
  .header-ul-btn{
    font-size: .6rem !important;
    font-weight: 600!important;
    letter-spacing: 0!important;
  }
  .header-ul-btn .icon{
    font-size: .9rem;
  }
  .header-ul li{
    padding: 0;
  }
}
</style>