var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"no-data-text":_vm.$t('NoData'),"no-results-text":_vm.$t('NoResult'),"loading-text":_vm.$t('PleaseWait'),"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Cities')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('EnterName'),"outlined":"","dense":"","hide-details":""},on:{"change":_vm.searchFilter},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.HasPermission('RegionControl'))?_c('v-btn',{staticClass:"primary float-left pa-2 ma-2",attrs:{"small":""},on:{"click":_vm.AddItem}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")]):_vm._e()],1),(_vm.dialog)?_c('EditCityDialog',{attrs:{"government-id":_vm.selected.id,"item":_vm.editedItem,"dialog":_vm.dialog,"edited-index":_vm.editedIndex},on:{"edited":_vm.itemEdited}}):_vm._e(),(_vm.dialogDelete)?_c('DeleteDialog',{attrs:{"action":'regionsModule/DeleteAirport',"item":_vm.editedItem,"dialog":_vm.dialogDelete},on:{"result":_vm.deleteItemConfirm}}):_vm._e()],1)]},proxy:true},{key:"item.imgePath",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2 rounded",attrs:{"src":_vm.APP_URL+item.imgePath,"max-width":"80px"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.menu_items.some(function (s){ return s.show === true; }))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menu_items),function(m_item,index){return (m_item.show)?_c('v-list-item',{key:'action_'+index,on:{"click":function($event){return m_item.Click(item)}}},[_c('v-list-item-icon',{},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(m_item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(m_item.title))])],1):_vm._e()}),1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoData'))+" "),(_vm.selected)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_c('v-icon',[_vm._v(" mdi-reload ")])],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }