<template>
  <section id="about">
    <v-container fluid>
      <v-row  class="pa-2" justify="center" align="center">

        <v-col cols="12" md="4" lg="4">
          <v-row align="center" justify="center">
            <v-col class="justify-content-center pa-4" cols="12" md="12" lg="12">
              <v-img  max-width="500px" class="v-responsive img-fluid" :src="require('@/assets/Payment/paymentIcons.png')" alt="mada"></v-img>

            </v-col>
<!--            <v-col cols="12" md="12" class="d-md-flex">-->
<!--              <img class="main-img img-fluid" :src="require('@/assets/Payment/mada.png')" alt="mada">-->

<!--              <img  class="main-img img-fluid v-responsive" :src="require('@/assets/Payment/fawry.png')" alt="fawry" >-->


<!--            </v-col>-->
<!--            <v-col cols="12" md="12" class="d-lg-flex">-->

<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/orange.png')" alt="orange" />-->
<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/we.png')" alt="we" />-->
<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/etisalat.png')" alt="etisalat" />-->
<!--              <img class="sub-img img-fluid" :src="require('@/assets/Payment/vodafone.png')" alt="vodafone" />-->
<!--              <img  class="sub-img img-fluid" style="max-width: 100px"  :src="require('@/assets/Payment/visa.png')" alt="visa" />-->
<!--              <img  class="sub-img img-fluid" style="max-width: 100px"   :src="require('@/assets/Payment/master.png')" alt="master" />-->

<!--            </v-col>-->

          </v-row>
        </v-col>
<!--        <v-col cols="12" md="5" lg="5">-->
<!--          <v-row justify="center" align="center">-->
<!--            <v-col cols="12">-->
<!--              <h3 class="pl-2 pr-2">{{$t("PoweredByFawry")}}</h3>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="12" class="d-lg-flex">-->

<!--              &lt;!&ndash;              <v-row justify="center">&ndash;&gt;-->
<!--              &lt;!&ndash;                <v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                  &ndash;&gt;-->
<!--              &lt;!&ndash;                </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                &ndash;&gt;-->
<!--              &lt;!&ndash;              </v-row>&ndash;&gt;-->
<!--              <img  class="sub-img img-fluid" style="max-width: 100px"  :src="require('@/assets/Payment/visa.png')" alt="visa" />-->
<!--              <img  class="sub-img img-fluid" style="max-width: 100px"   :src="require('@/assets/Payment/master.png')" alt="master" />-->
<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/orange.png')" alt="orange" />-->
<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/we.png')" alt="we" />-->
<!--              <img  class="sub-img img-fluid"  :src="require('@/assets/Payment/etisalat.png')" alt="etisalat" />-->
<!--              <img class="sub-img img-fluid" :src="require('@/assets/Payment/vodafone.png')" alt="vodafone" />-->


<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
        <v-col  cols="12" md="8"  lg="8">
          <v-row>
            <v-col cols="12" sm="6" md="7" lg="7">
                <span class="pa-4">
                  <h3 class="pl-4 pr-4">  الصفحات :</h3>

            <v-btn link text large  class="footer-btn " :to="{name: 'Pages'  , params:{Title:'PrivacyPolicy'}}">
              {{$t('PrivacyPolicy')}}
            </v-btn>
            <v-btn link text large  class="footer-btn " :to="{name: 'Pages' , params:{Title:'TermsAndConditions'}}">
              {{$t('TermsAndConditions')}}
            </v-btn>
            <v-btn link text large  class="footer-btn" :to="{name: 'Pages' , params:{Title:'Help'}}">
              {{$t('Help')}}
            </v-btn>
            <v-btn link text large  class="footer-btn" :to="{name: 'Pages' , params:{Title:'Faqs'}} ">
              {{$t('Faqs')}}
            </v-btn>
            <v-btn link text large  class="footer-btn" :to="{name: 'Pages' , params:{Title:'About'}} ">
              {{$t('About')}}
            </v-btn>
          </span>
            </v-col>
            <v-col class="d-flex justify-content-center" sm="6" cols="12" md="5" lg="5">
              <span>
            <img style="max-width: 100px" class="v-responsive " :src="require('@/assets/Logo2/logoWithoutWord.png')" alt="logo"/>

          </span>
              <span class="pa-3 pt-8">
                <h3 class="text-h6">Lak Offers</h3>
              <h4>رقم الحساب الضريبي</h4>
            <h4>224962582</h4>
          </span>
            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default  {
  name:"PaymentSection",

}
</script>
<style scoped>
#about , .grey-b {
  background-color: #f4f7f5;
}
.main-img{
  max-width: 150px;
  padding: 10px;
}
.sub-img{
  max-width: 70px;
  padding: 20px;
}

.footer-btn{
  font-weight: 600 !important;
  letter-spacing: 0!important;
  color: #00195c;
}
</style>
