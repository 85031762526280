<template>
  <section id="download" class="pa-8">
    <contact-section></contact-section>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" class="">
          <v-row align="center" justify="center">

            <v-col  cols="12" lg="6" md="6" sm="12" class="white--text custom-text-right">
              <p style="font-weight: 600 !important;" class="text-h4 pa-0 ma-0">{{$t('PName')}}</p>


              <p class="white--text pt-0">
                {{$t('FollowUs')}}
              </p>
              <SocialIcons></SocialIcons>

            </v-col>
            <v-divider v-if="mobile"  style="height: auto ; border-color: rgb(179 179 179)" vertical></v-divider>

            <v-col
              cols="12"
              md="4"
              sm="12"
              class="custom-text-left mb-2"
            >
              <h1 class="custom-text-right mb-4" style="color: white" >
                {{$t('DownloadNowText')}}
              </h1>
              <div class="custom-text-right">
                <v-btn plain :ripple="false" rounded outlined  href="#" target="_blank" color="white" class="ma-1  download-app-btn">
                  <v-img  max-width="130"  src="@/assets/img/google-play-badge.png"></v-img>
                </v-btn>
                <v-btn  plain :ripple="false" rounded outlined href="#" large color="white" class="ma-1  download-app-btn" >
                  <v-img  max-width="120"  src="@/assets/img/apple-store-badge.png"></v-img>
                </v-btn>
              </div>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img class="v-responsive" src="~@/assets/img/borderWavesBlue.svg"/>
      </div>
    </v-container>

  </section>
</template>

<style scoped>

</style>
<script>
import SocialIcons from "@/components/SocialIcons";
import ContactSection from "@/components/ContactSection.vue";
export default {
  components:{
    ContactSection,
    SocialIcons,

  },
  props:{
    mobile : false
  }
}
</script>

