<template>
  <v-footer class="app-footer row"  padless style="border-top: 1px solid rgba(204,204,204,0.65)">
    <v-col cols="12"  class="text-center" lg="12" md="12" sm="12">
      <h4 style="color: #565656 ; font-size: 1rem  ; font-weight: bolder" class="pl-4 pr-4">Copyright @2022 <span style="color: #c44118">Lak Offers</span> Team</h4>
    </v-col>

  </v-footer>
</template>

<style scoped>
.app-footer{
  text-align: left!important;
}
.footer-btn{
  font-weight: 600 !important;
  letter-spacing: 0!important;
  color: #00195c;
}
</style>

<script>
import PaymentSection from "@/components/PaymentSection";
export default {
  components: {PaymentSection}
};
</script>
