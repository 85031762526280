<template>
  <div>
    <v-scroll-y-reverse-transition hide-on-leave>
    <sidebar @DrawerEvent="DrawerEvent" :hide-sys-bar="hideSysBar" :clipped="clipped" :drawer="drawer" v-if="GetLoginStatus && drawer" :miniVariant="miniVariant"/>
    </v-scroll-y-reverse-transition>
    <transition :duration="150">
      <app-top-header @OnClientArea="SetClientAreaData" v-if="hideSysBar" :hide-sys-bar="hideSysBar"></app-top-header>
    </transition>
      <v-app-bar
           :class="['white' , 'pa-2' , this.hideSysBar ? 'mt-12':''  , 'appbar']" fixed flat app

      >

        <v-app-bar-nav-icon class="ma-0"  v-if="GetLoginStatus" @click="changeDrawer" />
        <v-btn
            icon
            v-if="GetLoginStatus"
            @click.stop="miniVariant = !miniVariant"
        >
          <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
        </v-btn>

        <v-btn  text class="mt-1 pa-0" style="cursor: pointer; overflow: hidden; min-width: 0" @click="()=>{ $route.name !== 'Home' ? $router.push({name:'Home'}) :null}">
          <img  class="img-fluid logo  " :src="require('@/assets/Logo2/logoWithoutWord.png')" alt="logo"/>

          <h2 class="app-name mx-1" style="font-weight: 600">
            {{$t('AppName')}}

          </h2>
        </v-btn>

        <!--        <v-img @click="GoToHome()" class="v-responsive v-responsive__sizer" max-width="120px" max-height="30px"  style="cursor: pointer" src="../assets/logo.png"></v-img>-->
        <v-spacer/>

        <LoginRegisterDialog v-if="clientAreaDialog" @OnResult="OnClientAreaResult"  :dialog="clientAreaDialog" :type="clientAreaType"></LoginRegisterDialog>
        <v-btn
            icon
            v-if="GetLoginStatus"
            link

            :to="{name:'Chat'}"
            
        >

          <v-icon >
            mdi-chat-outline

          </v-icon>
          <v-badge
              v-if="GetMessagesCount>0"
              color="red"
              class=""
              :content="GetMessagesCount"
          >
          </v-badge>
        </v-btn>


        <Notifications v-if="GetLoginStatus"></Notifications>
        <v-divider
            vertical
            class="mx-3"
              v-if="GetLoginStatus"
        >

        </v-divider>

        <UserMenu v-if="GetLoginStatus"></UserMenu>
        <guest-menu @OnClientArea="SetClientAreaData" v-else></guest-menu>


      </v-app-bar>



  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import UserMenu from "./UserMenu";
import GuestMenu from "./GuestMenu";
import {  mdiSearchWeb } from '@mdi/js'
import AppTopHeader from "@/components/AppTopHeader";
import LoginRegisterDialog from "@/Layout/LoginRegisterDialog";
import Notifications from "@/Layout/Notifications";
export default {
  name:"navbar",
  components: {
    Notifications,
    LoginRegisterDialog,
    AppTopHeader,
    Sidebar,
    UserMenu,
    GuestMenu
  },
  computed:{
    GetLoginStatus(){
      return this.$store.getters['authModule/IsLoggedIn'];
    },
    GetNotificationCount() {
      return this.$store.getters['notificationModule/getNotificationCount'];
    },
    GetMessagesCount() {
      return this.$store.getters['chatModule/GetMessagesCount'];
    },
    GetFirstRole(){
      return this.$store.getters['authModule/GetFirstRole'];
    },
    IsRequireLogin(){
      return this.$store.getters['pointsPackageModule/IsRequireLogin'];
    },
  },
  data () {
    return {
      clipped : false,
      drawer : false,
      miniVariant:false,
      right: true,
      clientAreaDialog:false,
      clientAreaType:'login',
      hideSysBar : true,
      hideNav : false,
      rightDrawer: false,
      title: this.$i18n.t('AppName'),
            // Icons
      icons: {
        mdiSearchWeb,
      },
    }
  },
  mounted() {
    //window.addEventListener('scroll', this.handleScroll);
  },
  watch:{
    IsRequireLogin(val){
      if(val){
        this.SetClientAreaData('login');
      }
    }
  },
  methods:{

    handleScroll(event) {
      let pos = window.top.scrollY;
        if(pos>200 && this.hideSysBar){
          this.hideNav =true;
          setTimeout(()=>{
            this.hideNav = false;
          } , 10)
          this.hideSysBar= false;
        }
        if(pos<200 && !this.hideSysBar){
          this.hideNav =true;
          setTimeout(()=>{
            this.hideNav = false;
          } , 10)
          this.hideSysBar =true;
        }


    },
    changeDrawer(){
      this.drawer = !this.drawer;
    },
    DrawerEvent(value){
      this.drawer= value;
    },
    OnClientAreaResult(){
      this.clientAreaDialog= false;
      if(this.IsRequireLogin){
        this.$store.dispatch('pointsPackageModule/CompleteSubscription' , this.GetLoginStatus);
      }
    },
    SetClientAreaData(obj){
      this.clientAreaDialog = true;
      this.clientAreaType = obj;


    },
    GoToHome(){
      this.$router.push({name:'Home'});
    },
    OnClientArea(){

    }
  }

}
</script>

<style scoped>
.user-profile-menu-content .v-list-item {

    min-height: 2.5rem !important;

}

.logo{
  will-change: filter;
  max-width: 27px;
  height: 27px;
}

.appbar{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 8%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
}
@media screen and (max-width: 950px) {
  .appbar{
    /*right:10px !important;*/
  }
  .app-name{
    display: none;
  }

}
</style>